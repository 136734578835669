import TabAppContext from '@component/TabAppContext'
import { AccountBalanceRoundedIcon, AttachMoneyRoundedIcon, Box, Button, CircularProgress, DeliveryDiningRoundedIcon, Dialog, DialogActions, DialogContent, LocalPrintshopRoundedIcon, PaymentsRoundedIcon, Typography }from '@component/UIComponents'
import { currencyFormat } from '@util/currencyFormat'
import { useTranslation } from 'react-i18next'
import Cash from './components/Cash'
import { useGetSale } from '@feature/Reports/Sales/hooks/useSales'
import { useCallback, useEffect, useRef, useState } from 'react'
import { PriceChangeRounded } from '@mui/icons-material'
import Consignment from './components/Consignment'
import Credit from './components/Credit'
import { useMemo } from 'react'
import { useCashierPaymentFormik } from '@feature/Sales/hooks/useCashierPOS'
import Advance from './components/Advance'
import Form from '@component/Form'
import { useRecordInvoiceInformationAndPayments, useRegisterNewPaymentForSale } from '@feature/Sales/hooks/usePOS'
import { Toast } from '@util/swal'
import { arrayReducer } from '@util/helpers'
import FormAddDeliveryInformation from '@feature/Deliveries/components/FormAddDeliveryInformation'
import PopupAlert1 from '@component/PopupAlert1'
import Invoice from '@feature/Reports/Invoice'
import { useReactToPrint } from 'react-to-print'
import CreditNote from './components/CreditNote'

const SalePaymentWindow = ({ isMobile=false, balanceValue=null, onCloseInvoiceInformation=()=>null, updateInvoiceInformation=false, openProductInformationWindow=false, open=false, onClose=()=>null, invoiceId=undefined, omitPayments=[], addNewPayment=false }) => {
    const [showPopupMessage, setShowPopupMessage] = useState("")
    const [tab, setTab] = useState("cash")
    const { sale, isFetching, isSuccess } = useGetSale({ ref: invoiceId }, {refetchOnMountOrArgChange: true})
    const { recordInvoiceInformationAndPayments } = useRecordInvoiceInformationAndPayments()
    const [showAddDeliveryInformationForm, setShowAddDeliveryInformationForm] = useState(false)

    const currentBalance = useMemo(() => {
        if( balanceValue !== null && balanceValue !== undefined && balanceValue >= 0 ){
            return balanceValue
        }else{
            return (sale?.balance??0)
        }
    }, [balanceValue, sale])

    const { t } = useTranslation()
    const { setFieldValue, values, resetValues } = useCashierPaymentFormik({ defaultValues: sale })
    const { payments, creditExpirationDate, saleId } = values
    const { registerNewPaymentForSale, isLoading } = useRegisterNewPaymentForSale()

    const currentRef = useRef(null)
    const handlePrint = useReactToPrint({ content: () => currentRef.current });

    const handleChange = useCallback(({value=0, boxName, refId }) => {
        if( tab === "cash" ){
            setFieldValue("payments", [ { amount: value, wallet: "cash", boxName } ])
        }
        if( tab === "consignment" ){
            setFieldValue("payments", [ { amount: value, wallet: "consignment", boxName, refId } ])
        }
        if( tab === "advance" ){
            setFieldValue("payments", [ { amount: value, wallet: "advance", boxName, refId } ])
        }
        if( tab === "credit" ){
            setFieldValue("creditExpirationDate", value)
        }
        if( tab === "creditNote" ){
            setFieldValue("collectCreditNote", value)
        }
    }, [tab, setFieldValue])

    const tabs = useMemo(() => {
        let returned = (payments[0]?.amount??0)-(sale?.total??0)
        // returned = returned <= 0 ? 0 : returned
        let r = [
            { label: t('Efectivo'), value: "cash", component: <Cash balance={currentBalance} onChange={handleChange} value={(payments[0]?.amount??0)} returned={returned} />, icon: <PaymentsRoundedIcon /> },
            { label: t('Consignación'), value: "consignment", component: <Consignment maxAmount={currentBalance} onChange={handleChange} />, icon: <AccountBalanceRoundedIcon /> },
            { label: t('Anticipo'), value: "advance", component: <Advance maxVal={currentBalance} onChange={handleChange} customerId={(sale?.customer?._id??null)} value={(payments[0]?.amount??0)} />, icon: <PriceChangeRounded />, disabled: !Boolean((sale?.customer?._id??null)) },
            { label: t('Crédito'), value: "credit", component: <Credit onChange={handleChange} value={creditExpirationDate} />, icon: <AttachMoneyRoundedIcon /> },
            { label: t('Nota'), value: "creditNote", component: <CreditNote CustomerID={(sale?.customer?._id??null)} AmountToPay={currentBalance} onChange={handleChange} />, icon: <AttachMoneyRoundedIcon /> },
        ]
        r = r.filter((n) => !omitPayments.some((b) => n.value === b))
        return r
    }, [t, handleChange, payments, sale, creditExpirationDate, omitPayments, currentBalance])

    useEffect(() => {
        setFieldValue("payments", [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab])

    const handleKeyDown = (event) => {
        const { code="" } = event
        if( code === "ControlLeft" || code === "ControlRight" ){
            event.preventDefault()
            const tabIndex = tabs.findIndex((n) => (n?.value??"") === tab)
            let newTab = (tabs[tabIndex+1]?.value??"")
            if( newTab === "advance" && !Boolean((sale?.customer?._id??null)) ){
                newTab = (tabs[tabIndex+2]?.value??"")
            }
            if( Boolean(newTab) ){
                setTab(newTab)
            }else{
                setTab("cash")
            }
        }
    }

    const closeAllWindows = () => {
        onClose()
        resetValues()
        onCloseInvoiceInformation()
    }

    const handleSubmit = ({ print=false }) => {
        if( addNewPayment ){
            const { payments=[], saleId=null, collectCreditNote=null } = values
            const payload = { ...((payments[0]??{})), collectCreditNote, saleId }
            if( (payload?.amount??0) <= 0 && ((collectCreditNote?.amount??0) <= 0) ){
                Toast.fire({
                    icon: "warning",
                    text: "Añada un pago."
                })
            }else{
                registerNewPaymentForSale(payload, {
                    closeForm: () => closeAllWindows()
                })
            }
        }else{
            const totalPayments = arrayReducer({ list: payments, reducer: (n) => (n?.amount??0)})
            const remaining = (sale?.total??0)-totalPayments

            if( !Boolean(sale?.customer?._id??"") && (remaining >= 1) && (tab === "credit") ){
                setShowPopupMessage("Añada un cliente")
                return
            }
            if( tab === "cash" ){
                if( remaining >= 1 || totalPayments === 0 ){
                    setShowPopupMessage("Falta dinero!")
                    return
                }
            }
            recordInvoiceInformationAndPayments(values, {
                clearForm: () => {
                    if( print ){
                        handlePrint()
                        setTimeout(() => {
                            closeAllWindows()
                        }, 1000)
                    }else{
                        closeAllWindows()
                    }
                }
            })
        }
    }

    useEffect(() => {
        if( !Boolean(saleId) && open ){
            setFieldValue("saleId", (invoiceId))
        }
    }, [setFieldValue, invoiceId, saleId, open])

    useEffect(() => {
        if( !open ){
            resetValues()
            setTab("cash")
        }
    }, [open, resetValues])


    return(
        <>
            <Dialog sx={ isMobile ? { '& .MuiDialog-paperScrollPaper' : { top: '-22vh' } } : {}} onKeyDown={handleKeyDown} PaperProps={{ elevation: 0, }} transitionDuration={0} fullWidth open={open} onClose={onClose} >
                { ((isFetching === false) && (isSuccess === true)) ?
                    <Form onSubmit={handleSubmit} >
                        <DialogContent>
                            <Typography mb={1} fontSize={'.875rem'} width={'100%'} fontWeight={'bold'} textTransform={'uppercase'} > Cliente: <Box component={'span'} sx={{ color: (theme) => theme.palette.primary.main }} > {t((sale?.customer?.name??"some"))} </Box> </Typography>
                            <Typography letterSpacing={"-1px"} fontSize={'2rem'} width={'100%'} textAlign={'center'} fontWeight={'bold'} textTransform={'uppercase'} > venta: <Box component={'span'} sx={{ color: (theme) => theme.palette.primary.main }} > {currencyFormat((currentBalance))} </Box> </Typography>
                            <Box display={'flex'} flexDirection={'column-reverse'} >
                                <TabAppContext
                                variant={'scrollable'}
                                    onChange={(_, newTab) => setTab(newTab)}
                                    centered
                                    tab={tab}
                                    tabs={tabs}
                                />
                            </Box>
                        </DialogContent>
                        <DialogActions sx={{ paddingTop: 0 }} >
                            <Button loading={isLoading} startIcon={<DeliveryDiningRoundedIcon />} onClick={() => setShowAddDeliveryInformationForm(true)} > Enviar a domicilio </Button>
                            <Button loading={isLoading} disableElevation startIcon={<LocalPrintshopRoundedIcon />} onClick={() => handleSubmit({ print: true })} > Guardar e imprimir </Button>
                            <Button type='submit' loading={isLoading} variant='contained' disableElevation > Guardar </Button>
                        </DialogActions>
                    </Form>
                    :
                    <Box padding={2} height={300} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'} >
                        <CircularProgress />
                        <Box fontSize={'.875rem'} mt={2} fontWeight={'500'} >
                            Esperando información de la factura...
                        </Box>
                    </Box>
                
                }
            </Dialog>
            <FormAddDeliveryInformation invoiceRef={invoiceId} open={showAddDeliveryInformationForm} onClose={() => setShowAddDeliveryInformationForm(false)} />
            <PopupAlert1 open={Boolean(showPopupMessage)} onClose={() => setShowPopupMessage("")} text={showPopupMessage} textButton='Corregir' />
            <Box displayPrint={'block'} display={'none'} >
                <Invoice ref={currentRef} {...sale} />
            </Box>
        </>
    )
}

export default SalePaymentWindow