import { Box, Card, CardContent, Chip, Grid, LocalPrintshopRoundedIcon, PaymentsRoundedIcon, Stack } from "@component/UIComponents"
import { PlagiarismRounded } from "@mui/icons-material"
import { currencyFormat } from "@util/currencyFormat"
import { forwardRef, useCallback } from "react"
const Item = forwardRef(({ onClickItem=()=>null, customerName = "", consecutive = "", sellerName = "", total = 0, isOrder = false, onPay=()=>null, onPrint=()=>null, onShow=()=>null, date=undefined, hideActions=false }, ref) => {

    const Action = ({ label="", icon=undefined, onClick=()=>null, id=undefined }) => <Chip
        variant="outlined"
        color="primary"
        size="small"
        clickable
        label={label}
        icon={icon}
        onClick={onClick}
        id={id}
        sx={{
            padding: 1
        }}
    />

    const handleClickItem = useCallback((event) => {
        const { id } = event.target.closest("div")
        const ignoreClickElem = ["showBill", "print", "pay"]
        if( !ignoreClickElem.some((ignore) => ignore === id ) ){
            onClickItem()
        }
    },[onClickItem])

    return (
        <Card onClick={handleClickItem} ref={ref} variant="outlined" sx={{
            width: "100%",
            minWidth: 300,
            cursor: "pointer",
            userSelect: "none",
            transition: ({ transitions }) => transitions.create('all'),
            '&:hover': {
                outline: ({ palette }) => `1px solid ${palette.primary.main}`,
                transform: "scale(1.02)",
            },
            '&:active': {
                outline: ({ palette }) => `1.5px solid ${palette.primary.main}`,
                transform: "scale(1)",
            }
        }} >
            <CardContent sx={{ padding: 1.2, paddingBottom: ({ spacing }) => `${spacing(1.2)} !important` }} >
                <Grid container sx={{
                    fontWeight: 'bold',
                    fontSize: ".875rem",
                    WebkitTextStroke: .3
                }} >
                    <Grid xs={12} >
                        <Stack direction={'row'} justifyContent={'space-between'} >
                            <Box sx={{ color: ({ palette }) => palette.primary.main }} className="truncate-text" maxWidth={250} >C: {customerName}</Box>
                            <Box> {consecutive} </Box>
                        </Stack>
                    </Grid>
                    <Grid xs={12} >
                        <Stack direction={'row'} justifyContent={'space-between'} >
                            <Box>V: {sellerName}</Box>
                            <Box sx={{ color: ({ palette }) => palette.primary.main }} > {currencyFormat(total)} </Box>
                        </Stack>
                    </Grid>
                    { hideActions === false &&
                        <Grid xs={12} sx={{ mt: 1 }} >
                            <Stack direction={'row'} justifyContent={'end'} gap={1} >
                                <Action id={"showBill"} label={isOrder ? "Ve pedido" : "Ver venta"} icon={<PlagiarismRounded />} onClick={onShow} />
                                {/* <Action id={"print"} label={"Imprimir"} icon={<LocalPrintshopRoundedIcon />} onClick={onPrint} /> */}
                                <Action id={"pay"} label="Pagar" icon={<PaymentsRoundedIcon />} onClick={onPay} />
                            </Stack>
                        </Grid>
                    }
                    { Boolean(date) &&
                        <Grid xs={12} mt={1.2} lineHeight={0.5} >
                            <Box textAlign={'center'} fontWeight={'500'} fontSize={'.750rem'} >
                                {date}
                            </Box>
                        </Grid>
                    }
                </Grid>
            </CardContent>
        </Card>
    )
})

export default Item